/* eslint react/prop-types: 0 */
/* eslint react/destructuring-assignment: 0 */
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ButtonRow, ButtonRowWrapper } from '../styled-components';
import config from '../../../config.json';
import AuthContext from '../../../app/authProvider';

const LearnerExternalRecords: React.FC<{useRegistration: boolean}> = ({ ...props }) => {
  // eslint-disable-next-line
  const [urlConfig, setUrlConfig] = useState(config.urls);
  const [applicationUrl] = useState(props.useRegistration ? '/learner-registration' : '/applicants');

  return (
    <AuthContext.Consumer>
      {(context) => context && (
        <>
          <ButtonRowWrapper>
            <ButtonRow>
              <Button as="a" variant="success" href={`${applicationUrl}/${context.learnerId}`} target="_blank" block>
                Application
              </Button>
            </ButtonRow>
            <ButtonRow>
              <Button as="a" variant="success" href={`${urlConfig.chargebee}/customers?Customers.search=${context.learnerId}`} target="_blank" block>
                Payment Record
              </Button>
            </ButtonRow>
          </ButtonRowWrapper>
        </>
      )}
    </AuthContext.Consumer>
  );
};

export default LearnerExternalRecords;
