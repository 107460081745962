import { AnyAction } from 'redux';
import IError from '../../../models/Error';
import Learner, { LearnerFlags } from '../../../models/Learner';
import ProgramEnrollment from '../../../models/ProgramEnrollment';
import {
  GET_LEARNER_PROFILE_PENDING,
  GET_LEARNER_PROFILE_FULFILLED,
  GET_LEARNER_PROFILE_REJECTED,
  GET_LEARNER_INFO_PERMISSIONS_PENDING,
  GET_LEARNER_INFO_PERMISSIONS_SUCCESS,
  GET_LEARNER_INFO_PERMISSIONS_ERROR,
  GET_LEARNER_PROFILE_VIEW_PERMISSION_PENDING,
  GET_LEARNER_PROFILE_VIEW_PERMISSION_SUCCESS,
  GET_LEARNER_PROFILE_VIEW_PERMISSION_ERROR,
  GET_LEARNER_PAYMENT_STATUS_FULFILLED,
  GET_LEARNER_PAYMENT_STATUS_PENDING,
  GET_LEARNER_PAYMENT_STATUS_REJECTED,
  UPDATE_FIELD_VALUE,

  GET_LEARNER_ACHIEVEMENTS_SUCCESS,
  GET_LEARNER_ACHIEVEMENTS_PENDING,
  GET_LEARNER_ACHIEVEMENTS_REJECTED,
  GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_PENDING,
  GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_SUCCESS,
  GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_ERROR,
  GET_LPP_PERMISSIONS_PENDING,
  GET_LPP_PERMISSIONS_SUCCESS,
  GET_LPP_PERMISSIONS_ERROR,

  UPDATE_LEARNER_ACHIEVEMENTS_STATUS,
  UPDATE_LEARNER_ACHIEVEMENTS_PENDING,
  UPDATE_LEARNER_ACHIEVEMENTS_REJECTED,

  GET_LEARNER_PROGRAM_ENROLLMENTS_FULFILLED,
  GET_LEARNER_PROGRAM_ENROLLMENTS_PENDING,
  GET_LEARNER_PROGRAM_ENROLLMENTS_ERROR,
  SET_LEARNER_PROGRAM_ENROLLMENT_START_DATE,

  UPDATE_ACTIVE_PROGRAM_ENROLLMENT,
  SET_LEARNER_PROGRAM_ENROLLMENT_PROGRAM_STATUS,
  GET_LEARNER_FLAGS_FULFILLED,
  GET_LEARNER_FLAGS_REJECTED,
  GET_LEARNER_FLAGS_PENDING,

} from './learner-profile.constants';
import {
  ILearnerAchievementDetails,
  IlearnerAchievementsPermissionObject,
  ILPPPermissionObject,
} from '../../actions/learner-profile.action';
import { IPaymentStatus, IPaymentStatusOptions } from '../../../components/learner-info/learner-info.model';
import { IApplicantInfo } from '../../../components/admissions/admissions.model';

const initialState = {
  learnerProfilePending: true,
  learnerProgramEnrollmentsPending: true,
  canReadLearnerProfilePending: true,
  learnerInfoPermissionsPending: true,
  learnerPaymentStatusPending: true,
  learnerPaymentStatus: IPaymentStatusOptions.UNPAID,
  learnerProfile: {
    Email: '',
    Name: '',
    BirthYear: 0,
    CountryOfResidence: '',
    OfficialName: '',
    Gender: '',
    SuccessAdvisorName: '',
    AccountName: '',
    OriginalCohort: '',
    AcceptanceStatus: '',
    ProductCode: '',
    ProductType: '',
    InstitutionName: '',
    InstitutionLanguage: '',
    InstitutionCountry: '',
    GraduationYear: 0,
    HighestEducationLevel: '',
  },
  learnerFlags: {
    LearnerId: '',
    ReadmissionDisabled: false,
  },
  learnerFlagsPending: true,
  programEnrollments: [],
  learnerInfoPermissionsObject: {
    canEditProgramStatus: false,
    canDeleteLearnerCourses: false,
    canViewEnrollmentData: false,
    canUpdateAcademicStanding: false,
    canEditStartDate: false,
    canEditLearnerInfo: false,
    canEditAdmissionParameters: false,
    canEditSuccessAdvisor: false,
    canEditAepStatus: false,
    canViewAepStatus: false,
    canApproveDropRequest: false,
    canEditCompletedCourse: false,
    canEditLearningPathPlan: false,
    canViewLearningPathPlan: false,
  },
  canReadLearnerProfile: false,
  canEditProgramStatus: false,
  canDeleteLearnerCourses: false,
  learnerProfileError: null,
  learnerProgramEnrollmentsError: null,
  learnerInfoPermissionsError: null,
  canReadLearnerProfileError: null,
  learnerGradesPermissionError: null,
  learnerPaymentStatusError: null,
  learnerAchievements: { progress: [], achievements: [], subscriptionCode: '' },
  learnerAchievementsPermissions: {
    canReadLearnerAchievements: false,
    canApproveLearnerAchievements: false,
  },
  learnerAchievementsPending: false,
  learnerAchievementsPermissionsError: null,
  lppPermissions: {
    canEditLearningPathPlan: false,
    canViewLearningPathPlan: false,
  },
  lppPermissionsPending: true,
  updateAchievementStatusPending: false,
  activeProgramEnrollmentId: '',
};

export interface ILearnerProfileState{
  learnerProfilePending: boolean;
  learnerProgramEnrollmentsPending: boolean;
  canReadLearnerProfilePending: boolean;
  learnerInfoPermissionsPending: boolean;
  learnerPaymentStatusPending: boolean;
  learnerPaymentStatus: IPaymentStatus;
  learnerProfile: Learner;
  learnerFlags: LearnerFlags;
  learnerFlagsPending: boolean;
  canEditProgramStatus: boolean;
  canReadLearnerProfile: boolean;
  programEnrollments: ProgramEnrollment[];
  learnerInfoPermissionsObject: {
    canEditProgramStatus: boolean,
    canViewEnrollmentData: boolean,
    canUpdateAcademicStanding: boolean,
    canDeleteLearnerCourses: boolean,
    canEditStartDate: boolean,
    canEditLearnerInfo: boolean,
    canEditAdmissionParameters: boolean,
    canEditSuccessAdvisor: boolean,
    canEditAepStatus: boolean,
    canApproveDropRequest: boolean,
    canViewAepStatus: boolean,
    canEditCompletedCourse: boolean,
    canViewLearningPathPlan: boolean,
    canEditLearningPathPlan: boolean,
  },
  learnerProfileError: IError | null;
  learnerFlagsError: IError | null;
  learnerProgramEnrollmentsError: IError | null;
  learnerInfoPermissionsError: IError | null;
  canReadLearnerProfileError: IError | null;
  learnerPaymentStatusError: IError | null;
  learnerAchievements: ILearnerAchievementDetails;
  learnerApplicantInfo: IApplicantInfo;
  learnerAchievementsPermissions: IlearnerAchievementsPermissionObject;
  learnerAchievementsPending: boolean;
  learnerAchievementsPermissionsError: IError | null;
  lppPermissions: ILPPPermissionObject;
  lppPermissionsPending: boolean;
  lppPermissionsError: IError | null;
  updateAchievementStatusPending: boolean;
  activeProgramEnrollmentId: string;
}

// @ts-ignore
export default (state: ILearnerProfileState = initialState, { type, payload }: AnyAction):
ILearnerProfileState => {
  switch (type) {
    case GET_LEARNER_INFO_PERMISSIONS_PENDING:
      return {
        ...state,
        learnerInfoPermissionsPending: true,
      };
    case GET_LEARNER_PROFILE_VIEW_PERMISSION_PENDING:
      return {
        ...state,
        canReadLearnerProfilePending: true,
      };
    case GET_LEARNER_PROFILE_PENDING:
      return {
        ...state,
        learnerProfilePending: true,
      };
    case GET_LEARNER_PROGRAM_ENROLLMENTS_PENDING:
      return {
        ...state,
        learnerProgramEnrollmentsPending: true,
      };
    case SET_LEARNER_PROGRAM_ENROLLMENT_START_DATE:
      return {
        ...state,
        learnerProgramEnrollmentsPending: false,
        programEnrollments: state.programEnrollments.map(
          // eslint-disable-next-line max-len
          (programEnrollment) => (programEnrollment.programEnrollmentId === payload.programEnrollmentId
            ? { ...programEnrollment, startDate: payload.startDate, comment: payload.comment }
            : programEnrollment),
        ),
        learnerProgramEnrollmentsError: null,
      };
    case SET_LEARNER_PROGRAM_ENROLLMENT_PROGRAM_STATUS:
      return {
        ...state,
        learnerProgramEnrollmentsPending: false,
        programEnrollments: state.programEnrollments.map(
          (programEnrollment) => (programEnrollment.programEnrollmentId
             === payload.programEnrollmentId
            ? {
              ...programEnrollment,
              programStatusId: payload.progamStatusId,
              programStatusName: payload.programStatusName,
            }
            : programEnrollment),
        ),
        learnerProgramEnrollmentsError: null,
      };
    case GET_LEARNER_PAYMENT_STATUS_PENDING:
      return {
        ...state,
        learnerPaymentStatusPending: true,
      };
    case GET_LEARNER_PROFILE_REJECTED:
      return {
        ...state,
        learnerProfilePending: false,
        learnerProfileError: payload,
      };
    case GET_LEARNER_PROGRAM_ENROLLMENTS_ERROR:
      return {
        ...state,
        learnerProgramEnrollmentsPending: false,
        learnerProgramEnrollmentsError: payload,
      };
    case GET_LEARNER_PAYMENT_STATUS_REJECTED:
      return {
        ...state,
        learnerPaymentStatusPending: false,
        learnerPaymentStatusError: payload,
      };
    case GET_LEARNER_PROFILE_VIEW_PERMISSION_ERROR:
      return {
        ...state,
        canReadLearnerProfilePending: false,
        canReadLearnerProfileError: payload,
      };
    case GET_LEARNER_INFO_PERMISSIONS_ERROR:
      return {
        ...state,
        learnerInfoPermissionsPending: false,
        learnerInfoPermissionsError: payload,
      };
    case GET_LEARNER_PROFILE_FULFILLED:
      return {
        ...state,
        learnerProfilePending: false,
        learnerProfile: payload.learner,
        learnerProfileError: null,
      };
    case GET_LEARNER_FLAGS_PENDING:
      return {
        ...state,
        learnerFlagsPending: true,
      };
    case GET_LEARNER_FLAGS_FULFILLED:
      return {
        ...state,
        learnerFlags: payload.learnerFlagsResp,
        learnerFlagsError: null,
        learnerFlagsPending: false,
      };
    case GET_LEARNER_FLAGS_REJECTED:
      return {
        ...state,
        learnerFlagsError: payload,
        learnerFlagsPending: false,
      };
    case GET_LEARNER_PROGRAM_ENROLLMENTS_FULFILLED:
      return {
        ...state,
        learnerProgramEnrollmentsPending: false,
        programEnrollments: payload.programEnrollments,
        learnerProgramEnrollmentsError: null,
      };
    case GET_LEARNER_INFO_PERMISSIONS_SUCCESS:
      return {
        ...state,
        learnerInfoPermissionsPending: false,
        learnerInfoPermissionsObject: {
          ...state.learnerInfoPermissionsObject,
          ...payload,
        },
        learnerInfoPermissionsError: null,
      };
    case GET_LEARNER_PROFILE_VIEW_PERMISSION_SUCCESS:
      return {
        ...state,
        canReadLearnerProfilePending: false,
        canReadLearnerProfile: payload.canReadLearnerInfo,
        learnerInfoPermissionsObject: {
          ...state.learnerInfoPermissionsObject,
          canEditLearnerInfo: payload.canEditLearnerInfo,
          canEditSuccessAdvisor: payload.canEditSuccessAdvisor,
        },
        canReadLearnerProfileError: null,
      };
    case GET_LEARNER_PAYMENT_STATUS_FULFILLED:
      return {
        ...state,
        learnerPaymentStatus: payload.paymentStatus,
        learnerPaymentStatusPending: false,
      };
    case UPDATE_FIELD_VALUE:
      return {
        ...state,
        learnerProfile: {
          ...state.learnerProfile,
          ...payload,
        },
      };
    case GET_LEARNER_ACHIEVEMENTS_PENDING:
      return {
        ...state,
        learnerAchievements: payload,
      };
    case GET_LEARNER_ACHIEVEMENTS_SUCCESS:
      return {
        ...state,
        learnerAchievements: payload,
      };
    case GET_LEARNER_ACHIEVEMENTS_REJECTED:
      return {
        ...state,
      };

    case UPDATE_LEARNER_ACHIEVEMENTS_STATUS:
      return {
        ...state,
        learnerAchievements: {
          ...state.learnerAchievements,
          achievements: [
            ...state.learnerAchievements.achievements.filter((item) => item.id !== payload.id),
            payload,
          ],
        },
        updateAchievementStatusPending: false,
      };
    case UPDATE_LEARNER_ACHIEVEMENTS_PENDING:
      return {
        ...state,
        updateAchievementStatusPending: true,
      };
    case UPDATE_LEARNER_ACHIEVEMENTS_REJECTED:
      return {
        ...state,
        updateAchievementStatusPending: false,
      };

    case GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_PENDING:
      return {
        ...state,
        learnerAchievementsPending: true,
      };
    case GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_SUCCESS:
      return {
        ...state,
        learnerAchievementsPermissions: {
          ...state.learnerAchievementsPermissions,
          ...payload,
        },
        learnerAchievementsPending: false,
      };
    case GET_LEARNER_ACHIEVEMENTS_PERMISSIONS_ERROR:
      return {
        ...state,
        learnerAchievementsPending: false,
        learnerAchievementsPermissionsError: payload,
      };

    case GET_LPP_PERMISSIONS_PENDING:
      return {
        ...state,
        lppPermissionsPending: true,
      };
    case GET_LPP_PERMISSIONS_SUCCESS:
      return {
        ...state,
        lppPermissions: {
          ...state.lppPermissions,
          ...payload,
        },
        lppPermissionsPending: false,
      };
    case GET_LPP_PERMISSIONS_ERROR:
      return {
        ...state,
        lppPermissionsPending: false,
        lppPermissionsError: payload,
      };

    case UPDATE_ACTIVE_PROGRAM_ENROLLMENT:
      return {
        ...state,
        activeProgramEnrollmentId: payload,
      };

    default:
      return state;
  }
};
